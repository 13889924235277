import React from 'react';
import RichText from '../RichText';
import { Button } from '../Button';
import Link from '../Link';

function MediaHeroWfeHrMetric(props) {
  const {
    title,
    subtitle: {
      subtitle,
    },
    heroImage,
  } = props;

  const heroBgImage = heroImage?.file.url;

  return (
      <section className="hr-metric-media-hero">
        <div className="hr-metric-media-hero__container">
          <div className="hr-metric-media-hero__background-image" style={{
            backgroundImage: `url(${heroBgImage})`,
            backgroundSize: 'auto 100%',
            backgroundPosition: 'top right',
            backgroundRepeat: 'no-repeat',
            minHeight: '360px',
          }}/>
          <div className="hr-metric-media-hero__inner">
            <div className="hr-metric-media-hero__content">
              <p className="hr-metric-media-hero__eyebrow"
                 data-sal="slide-up"
                 data-sal-delay="200"
                 data-sal-duration="1000"
                 data-sal-easing="ease"
              ><Link className="hr-metric-media-hero__eyebrow_link" href={"/saratoga-workforce-index-hr-metrics"}>Saratoga Workforce Index</Link></p>
              <h1 className="hr-metric-media-hero__title"
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
              >{title}</h1>
              <div className="hr-metric-media-hero__body"
                   data-sal="slide-up"
                   data-sal-delay="200"
                   data-sal-duration="1000"
                   data-sal-easing="ease">
                <h3>{subtitle}</h3>
              </div>
              <div className="hr-metric-media-hero__mobile-cta"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1000"
                data-sal-easing="ease">
                <Button theme="dark" href={'#form'}>Download the complete eBook</Button>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

MediaHeroWfeHrMetric.defaultProps = {
  title: 'Lorem ipsum dolor sit amet',
  subtitle: 'Duis ligula magna, egestas ac ipsum sit amet, mattis sodales nulla. Duis iaculis consectetur iaculis. Mauris ultricies et nisi vitae tempor.',
};

export default MediaHeroWfeHrMetric;
